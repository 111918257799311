import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import Title from "../../components/title"
// import Hero from "../../blocks/Hero"
import { FixedBanner } from "../../blocks/Banner"
import TextWithImage from "../../blocks/TextWithImage"

const SharingEconomy = () => (
  <Layout>
    {/* <Hero
      data={{
        title: "Paybase for the sharing economy",
        copy:
          '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur non lorem ultrices lobortis. Donec in mauris sed eros volutpat tempor eget nec purus. Duis sagittis tempor volutpat. Pellentesque ornare justo in felis efficitur, non semper nisi imperdiet. Morbi rutrum tellus nisl."',
        companyLogo: {
          src: "/who-is-it-for/marketplace/airbnb.png",
          alt: "John Smith, Product manager at Fast banana",
          copy: "John Smith, Product manager at Fast banana",
        },
        image: {
          src: "/who-is-it-for/sharing-economy/img1.png",
          alt: "placeholder image",
        },
        color1: "yellow",
        color2: "rose",
        color3: "blue",
      }}
    /> */}
    <Helmet>
      <title>Paybase - Sharing Economy</title>
      <meta property="og:title" content="Paybase - Sharing Economy" />
    </Helmet>
    <TextWithImage
      data={{
        title: "Paybase for Sharing Economy Platforms",
        content:
          "Route payments between your ‘renters’ and your ‘owners’ whilst automatically receiving your commission fee as the platform. Owners can then withdraw to their bank accounts at a time that suits them, or have their balance withdrawn automatically at a time that suits you! No operational overhead. No reconciliation. Paybase handles everything whilst you get paid.",
        image: {
          src: "/who-is-it-for/marketplace/img1.svg",
          alt: "placeholder alt",
        },
        links: [
          { copy: "Get in touch", to: "https://trustshare.co" },
          {
            copy: "Developer Centre",
            to: "https://developers.paybase.io/recipes/sharing-economy",
            color: "white",
            bg: "black",
            shadow: "white",
            hoverColor: "black",
          },
        ],
      }}
      options={{
        titleColor: "blue",
        copyColor: "grey",
        backgroundColor: "lightblue",
        padding: "3rem",
      }}
    />
    <Title copy="Using Paybase’s capabilities on your sharing economy platform?" />
    <TextWithImage
      data={{
        title: "Instant End-To-End Payments_",
        content:
          "Never make your users wait for payment. Unlike most providers that force sharing economy listers to wait up to 10 days, the Paybase solution offers instant end-to-end payments as standard. Enable your listers to spend their earnings immediately, encourage loyalty to your platform, reduce lead leakage and ensure that you stand out.",
        image: {
          src: "/who-is-it-for/gig-economy/img2.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#instant-payout" }],
      }}
      options={{ isTextRight: true, titleColor: "rose" }}
    />
    <TextWithImage
      data={{
        title: "White Label Escrow_",
        content:
          "Protect your users with escrow. On a platform that provides high-value item rentals, for instance, you may want payments to be held until a rental is completed and the item returned. Our lightweight solution enables you to utilise one-sided Due Diligence, helping you to seamlessly instil trust in your brand without negatively impacting UX.",
        image: {
          src: "/who-is-it-for/escrow/img4.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Get In Touch", to: "https://trustshare.co" }],
      }}
      options={{ titleColor: "blue" }}
    />
    <TextWithImage
      data={{
        title: "Dynamic Pricing for your Users_",
        content:
          "If, for instance, you are a platform connecting dog walkers with owners, you may wish to offer a tiered fee structure, rewarding your most active users with lower commission fees. This not only incentivises walkers to take on more clients but reduces the likelihood of them switching to a new platform.",
        image: {
          src: "/who-is-it-for/sharing-economy/img3.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#dynamic" }],
      }}
      options={{ isTextRight: true, titleColor: "rose" }}
    />
    <FixedBanner />
  </Layout>
)

export default SharingEconomy
